import React from 'react';
import {
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionSummaryField,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import { InspectionComponent } from './InspectionComponent';
import { Site } from '@energybox/react-ui-library/dist/icons';
import {
  getDetailFields,
  getSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  determinePhaseDisplayAndSource,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';
import { Row } from '../Pdf/utils';
import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

type Props = {
  data: InspectionDataFieldsByKey;
};

type DataFieldProps = {
  value: string | number;
  transform?: (value: string | number) => string | number;
  style?: Style;
  Container?: (props: { children: React.ReactNode }) => JSX.Element;
};

const DataField = ({
  value,
  transform,
  style,
  Container = ({ children }) => <Row gap={3}>{children}</Row>,
}: DataFieldProps) => {
  const transformedValue = transform ? transform(value) : value;
  return (
    <Container>
      <Text style={style}>{transformedValue || global.NOT_AVAILABLE}</Text>
    </Container>
  );
};

export const SiteTotal: React.FC<Props> = ({ data }) => {
  const summaryFields: InspectionSummaryField = getSummaryFields(data);
  const detailFields = [
    {
      name: 'Site Total Calculation',
      key: 'cal_method',
      transformValue: (value: SiteEnergyCalculationMode) =>
        value === SiteEnergyCalculationMode.MDP_MAINS
          ? 'MDP Main'
          : value === SiteEnergyCalculationMode.MDP_BREAKERS
          ? 'MDP Breakers'
          : value === SiteEnergyCalculationMode.DP_MAINS
          ? 'DP Main'
          : value === SiteEnergyCalculationMode.DP_BREAKERS
          ? 'DP Breakers'
          : value === SiteEnergyCalculationMode.FLAGGED_BREAKERS
          ? 'Flagged Breakers'
          : value,
    },
    {
      name: 'Number of Breakers',
      key: 'num_breaker',
      transformValue: (value: number) => value.toString(),
    },
  ];

  const columns: Columns<any>[] = React.useMemo(
    () => [
      {
        header: 'Breaker',
        width: '10%',
        cellContent: ({ breaker_title }) => (
          <DataField
            value={breaker_title}
            transform={field => field || global.NOT_AVAILABLE}
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          />
        ),
      },
      {
        header: 'Equipment',
        width: '15%',
        cellContent: ({ eq_title }) => (
          <DataField
            value={eq_title}
            transform={field => field || global.NOT_AVAILABLE}
          />
        ),
      },
      {
        header: 'Distribution Panel',
        width: '15%',
        cellContent: ({ panel_title }) => (
          <DataField
            value={panel_title}
            transform={field => field || global.NOT_AVAILABLE}
          />
        ),
      },
      {
        header: 'Phase',
        width: '5%',
        cellContent: ({ phase }) => {
          if (!isDefined(phase))
            return <DataField value={global.NOT_AVAILABLE} />;
          const { config_phase, erms_phase, proposed_phase } = phase;
          const { phaseLabel } = determinePhaseDisplayAndSource(
            config_phase,
            erms_phase || proposed_phase
          );
          return <DataField value={phaseLabel || global.NOT_AVAILABLE} />;
        },
      },
      {
        header: 'Active Power (kW)',
        width: '8%',
        cellContent: ({ active_power, breaker_title }) => (
          <DataField
            value={
              isDefined(active_power)
                ? formatDecimalValue(active_power / 1000, 2)
                : global.NOT_AVAILABLE
            }
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          />
        ),
      },
      {
        header: 'Current (A)',
        width: '8%',
        cellContent: ({ current, breaker_title }) => (
          <DataField
            value={
              isDefined(current)
                ? formatDecimalValue(current, 2)
                : global.NOT_AVAILABLE
            }
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          />
        ),
      },
      {
        header: 'Pf',
        width: '4%',
        cellContent: ({ pf }) => (
          <DataField
            value={
              isDefined(pf) ? formatDecimalValue(pf, 2) : global.NOT_AVAILABLE
            }
          />
        ),
      },
    ],
    [data]
  );

  return (
    <InspectionComponent
      title="Site Total"
      titleIcon={<Site />}
      summaryFields={summaryFields}
      detailFields={getDetailFields(
        detailFields,
        data,
        InspectionDetailLevel.ALL
      )}
      detailTable={
        data.lst_breaker
          ? [
              {
                props: {
                  dataIsLoading: false,
                  highlightAlternateRows: true,
                  columns: columns,
                  data: (data.lst_breaker as any)?.total
                    ? [
                        {
                          breaker_title: 'Total',
                          ...(data.lst_breaker as any)?.total,
                        },
                        ...(data.lst_breaker as any)?.breakers,
                      ]
                    : (data.lst_breaker as any)?.breakers || [],
                },
              },
            ]
          : undefined
      }
      noTableDataMessage="No Breakers Configured"
    />
  );
};
