import React from 'react';
import {
  determinePhaseDisplayAndSource,
  EnergyProWarning,
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  PhaseLabels,
  PowerMeteringType,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
  transformDhcpStatusValue,
  transformBusDeviceInfoValue,
  getSensorsOrActuatorsSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DataField, getErrorOrWarningIconForPdfField } from '../Pdf/utils';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
  isEPro2?: boolean;
};

const formatTableNumber = field => {
  if (
    (!field && field !== 0) ||
    field === undefined ||
    field === null ||
    isNaN(field)
  ) {
    return global.NOT_AVAILABLE;
  }
  return formatDecimalValue(field);
};

const mainsPhaseWarnings = Object.values(EnergyProWarning).filter(warning => {
  const warningStr = String(warning);
  return warningStr.includes('MAINS');
});

const getSensorErrorWarningIcon = (sensor_warnings: InspectionDataField[]) => {
  const filteredWarnings = sensor_warnings.filter(
    w =>
      w.warning && !mainsPhaseWarnings.includes(w.warning as EnergyProWarning)
  );
  if (filteredWarnings.length === 0) return null;
  const error = filteredWarnings.find(w => w.warning?.startsWith('ERROR'));
  return getErrorOrWarningIconForPdfField(
    error
      ? {
          error: error.warning,
        }
      : filteredWarnings[0]
  );
};

const getPortNumberFromIndex = (data: InspectionDataFieldsByKey) => {
  const matchNum = (data as any).index?.field?.match(/\d+/g);
  const portNum = matchNum ? parseInt(matchNum[matchNum.length - 1], 10) : -1;
  return portNum;
};

export const EnergyPro: React.FC<Props> = ({ data, isEPro2 }) => {
  const eproName = isEPro2
    ? InspectionComponentName.ENERGY_PRO2
    : InspectionComponentName.ENERGY_PRO;

  const columns: any[] = [
    (isEPro2 && {
      header: ' ',
      width: '1%',
      cellContent: ({ sensor_status, sensor_warnings }) => (
        <div style={{ flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
          <div
            style={{
              width: '6px',
              height: '6px',
              backgroundColor:
                sensor_status.field === 'Active' ? '#77eb85' : '#ccced1',
              borderRadius: '50%',
            }}
          />
          {sensor_warnings && getSensorErrorWarningIcon(sensor_warnings)}
        </div>
      ),
    }) ||
      undefined,
    {
      header: 'Index',
      width: '15%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: true,
      cellContent: ({ index }) => <DataField field={index} />,
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return isEPro2
          ? genericTableSort(
              getPortNumberFromIndex(a),
              getPortNumberFromIndex(b),
              sortDirection,
              SORT_IGNORED_VALUES,
              []
            )
          : genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
              'index',
              'field',
            ]);
      },
    },
    {
      header: 'Breaker',
      width: '10%',
      cellContent: ({ breaker }) => <DataField field={breaker} />,
    },
    {
      header: 'Equipment',
      width: isEPro2 ? '13%' : '14%',
      cellContent: ({ equipment_title }) => (
        <DataField field={equipment_title} transform={field => field || ' '} />
      ),
    },
    {
      header: 'Phase',
      width: '6%',
      cellContent: ({ phase, sensor_bus, sensor_warnings }) => {
        if (!phase) {
          return global.NOT_AVAILABLE;
        }
        const isMainBreaker = sensor_bus?.field === 0;
        const { config_phase, erms_phase, proposed_phase } = phase;
        const { phaseLabel, phaseSource } = determinePhaseDisplayAndSource(
          config_phase,
          erms_phase || proposed_phase
        );
        let phaseText = PhaseLabels[config_phase] || global.NOT_AVAILABLE;
        if (isEPro2 && !isMainBreaker) {
          phaseText = phaseLabel + phaseSource.padStart(5);
        }
        const filteredMainsPhaseWarnings = (sensor_warnings || []).filter(
          w =>
            w.warning &&
            mainsPhaseWarnings.includes(w.warning as EnergyProWarning)
        );
        const mainsPhaseError = filteredMainsPhaseWarnings.find(w =>
          w.warning?.startsWith('ERROR')
        );
        const phaseField = {
          field: phaseText,
          warning: filteredMainsPhaseWarnings[0]?.warning || null,
          error: mainsPhaseError ? mainsPhaseError.warning : null,
        };
        return <DataField field={phaseField} />;
      },
    },
    {
      header: 'Active Power (kW)',
      width: '5%',
      cellContent: ({ active_power }) => (
        <DataField field={active_power} transform={formatTableNumber} />
      ),
    },
    {
      header: 'Current (A)',
      width: '5%',
      cellContent: ({ current }) => (
        <DataField field={current} transform={formatTableNumber} />
      ),
    },
    {
      header: 'Power Factor',
      width: '4%',
      cellContent: ({ power_factor }) => (
        <DataField field={power_factor} transform={formatTableNumber} />
      ),
    },
  ].filter(v => v !== undefined);

  const fields = [
    {
      name: 'IP Address',
      key: 'ip',
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'DHCP Status',
      key: 'ip_setting',
      transformValue: transformDhcpStatusValue,
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
    },
    {
      name: 'Sensor Bus 1',
      key: 'bus_device_info_1',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'Power Metering Type',
      key: 'power_metering_type',
      transformValue: value =>
        PowerMeteringType[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Sensor Bus 2',
      key: 'bus_device_info_2',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
    },
    {
      name: 'Interval',
      key: 'interval',
      transformValue: value => `${value} sec`,
    },
  ];

  const epro2Fields = [
    {
      name: 'IP Address',
      key: 'ip',
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'Signal Strength',
      key: 'signal_strength',
      transformValue: value => (value ? `${value} dBm` : global.NOT_AVAILABLE),
    },
    {
      name: 'Power Metering Type',
      key: 'power_metering_type',
      transformValue: value =>
        PowerMeteringType[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Sensor Bus 1',
      key: 'bus_device_info_1',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'Interval',
      key: 'interval',
      transformValue: value => `${value} sec`,
    },
    {
      name: 'Sensor Bus 2',
      key: 'bus_device_info_2',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'Active Ports',
      key: 'num_active_ports',
      transformValue: value => '' + value,
    },
  ];

  const subtitle = getTitle(data);
  const allTabsTableData = data.ct_sensors as InspectionDataFieldsByKey[];
  const tableDataBySensorBus = {};
  allTabsTableData.forEach(ctSensor => {
    const sensorBusField = ctSensor.sensor_bus as InspectionDataField;
    const sensorBus = (sensorBusField?.field === 1 ||
    sensorBusField?.field === 2
      ? sensorBusField.field
      : 0) as number;
    if (!tableDataBySensorBus[sensorBus]) {
      tableDataBySensorBus[sensorBus] = [];
    }
    tableDataBySensorBus[sensorBus].push(ctSensor);
  });
  const tableTitles = [eproName, 'Bus 1', 'Bus 2'];
  const summaryFields = getSummaryFields(data);
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(
    data.ct_sensors as InspectionDataFieldsByKey[],
    {
      statisticsData: {
        result: 'sensors',
        errors: data.errors_sensors || 0,
        warnings: data.warnings_sensors || 0,
      },
    }
  );

  return (
    <InspectionComponent
      title={eproName}
      titleIcon={<DeviceIcon type={eproName} />}
      subtitle={subtitle}
      summaryFields={summaryFields}
      detailFields={getDetailFields(
        isEPro2 ? epro2Fields : fields,
        data,
        InspectionDetailLevel.ALL
      )}
      detailTableHeader={
        <ComponentHeader
          title={eproName + ' CT Sensors'}
          summaryFields={sensorsSummaryFields}
        />
      }
      detailTable={[0, 1, 2].map(idx => ({
        title: tableTitles[idx],
        props: {
          dataIsLoading: false,
          highlightAlternateRows: true,
          columns,
          data: tableDataBySensorBus[idx],
        },
      }))}
      noTableDataMessage="No Sensors Configured"
    />
  );
};
